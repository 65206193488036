import React from 'react';
export function Resume() {
    return (
      <div style={{overflow:'hidden',  height:'100%'}}
      >
      <iframe 
      title="resumePDF"
      src="https://drive.google.com/file/d/1HDXIyvLEKEoRbY6H5k33EmyP9QM3R-9Z/preview" 
      width='100%'
      height='100%'
      />
      </div>
    )
}